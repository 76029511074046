html,
body {
	background: #F1F3F6;
	font-family: Helvetica;
}

.MuiToolbar-gutters {
    padding-left: 0px;
    padding-right: 0px;
}

.MuiToolbar-regular {
    min-height: 56px;
}
@media screen and (min-width: 600px){
    .MuiToolbar-regular {
        min-height: 56px;
    }

    .MuiToolbar-gutters {
        padding-left: 0px;
        padding-right: 0px;
    }
}
