.loading {
    background: linear-gradient(30deg, #c3ebf7,  #85c1d3, #0E9AC4, #2A2A2A);
    color: white;
    opacity: 0.8;
    font-size: 30px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
  }
  
  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(5,end) 1000ms infinite;      
    animation: ellipsis steps(5,end) 1000ms infinite;
    width: 0px;
  }
  
  @keyframes ellipsis {
    to {
      width: 1.15em;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.5em;    
    }
  }